






import {Vue, Component, Prop} from 'vue-property-decorator'
import { SupportTicketMessage } from '@/classes/TayehApi/User/Query/GetUserTicket.query'
import Message from './SupportTicketMessage.vue'
@Component({
  components: {
    Message
  }
})
export default class SupportTicketMessagesList extends Vue {
    @Prop({default: []})
    private messages: SupportTicketMessage[];
    mounted () {
      console.log('messages',this.messages);
      
    }
}
