










import { Media, User } from '@/classes/TayehApi';
import { answerTicket } from '@/classes/TayehApi/Support';
import {Component, Vue} from 'vue-property-decorator'
import SupportTicketUploadFile from './SupportTicketUploadFile.vue'
@Component({
    components: {
        SupportTicketUploadFile
    }
})
export default class TicketSendMessageForm extends Vue {
    private message: string = null;
    private file: File = null;
    private async sendMessage () {
        let file_id: string = null;
        try {
            if (this.file) {
                file_id = await this.uploadFile()
            }
            await answerTicket(Number(this.$route.params.ticket_id), {
                message: this.message,
                file_id: file_id
            })
            this.file = null;
            this.message = null;
            this.$emit('change');
            alert('پیام شما ارسال شد. لطفا منتظر پاسخ پشتیبانی بمانید.')
        } catch (err) {
            console.log(err);
            alert('لطفا دوباره تلاش کنید')
        }
    }

    async uploadFile() {
        try {
            const res = await Media.uploadImage(this.file);
            return res.data.media.id;
        } catch (err) {
            console.log(err);
            return null;
        }
    }
}
