





























import { Component, Vue } from 'vue-property-decorator';
import AvatarView from '@/components/AvatarView.vue'
import TicketSendMessageForm from '../../components/SupportTicket/TicketSendMessageForm.vue'
import SupportTicketMessagesList from '../../components/SupportTicket/SupportTicketMessagesList.vue'
import SupportTicketSideInformation from '../../components/SupportTicket/SupportTicketSideInformation.vue'
import { User } from '@/classes/TayehApi';
import { GetSupportTicketResponse } from '@/classes/TayehApi/User/Query/GetUserTicket.query';
@Component({
    components: {
        AvatarView,
        TicketSendMessageForm,
        SupportTicketMessagesList,
        SupportTicketSideInformation
    }
})
export default class DashboardSupport extends Vue {

    ticket: GetSupportTicketResponse = null;
    async getSupportTicket () {
        const res = await User.getUserSupportTicket(Number(this.$route.params.ticket_id));
        this.ticket = res.data;
    }
    created () {
        this.getSupportTicket();
    }
}
