
























import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator'

@Component
export default class SupportTicketUploadFile extends Vue {
    @Prop()
    value: File;

    file: File = null;
    size = 0;
    dragover = false;
    action = `${process.env.VUE_APP_blobURL}/image`;
    drop(e: any) {
        let files = e.dataTransfer.files
        this.file = files[0]
        this.dragover = false;
        const size = ((this.file.size/1024)/1024)
        if (this.checkFileType(this.file.type)) {
            alert('تنها فایل های تصویر با فرمت png و jpg قابل آپلود می‌باشند.')
            this.file = null;
        } else if (size>2) {
            alert('حجم فایل نباید بیشتر از ۲ مگابایت باشد.')
            this.file = null;
        }
        this.$emit('update', this.file)
        this.$emit('change', this.file)
        this.$emit('input', this.file)
    }

    change(e: any) {
        let files = e.target.files
        this.file = files[0]
        this.dragover = false;
        const size = ((this.file.size/1024)/1024)
        if (this.checkFileType(this.file.type)) {
            alert('تنها فایل های تصویر با فرمت png و jpg قابل آپلود می‌باشند.')
            this.file = null;
        } else if (size>2) {
            alert ('حجم فایل نباید بیشتر از ۲ مگابایت باشد.');
            this.file = null;
        }

        this.$emit('update', this.file)
        this.$emit('change', this.file)
        this.$emit('input', this.file)
    }
    checkFileType (type: string) {
        return type!=='image/jpeg'&&type!=='image/jpg'&&type!=='image/png'
    }
}
