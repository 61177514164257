<template>
	<div class="avatar-view" :style="{
        width: width,
        height: width,
        borderRadius: radius,
        backgroundImage: 'url('+src+'), url(https://static.ty-sb1.tayeh.ir/tayeh-panel/assets/images/avatar.svg)'}"/>
</template>

<script>
	export default {
		name: 'ImageView',
		props: {
			src: String,
			width: {
				type: [String, Number],
				default: '90px'
			},
			radius: {
				type: [String, Number],
				default: '100%'
			}
		},
		data() {
			return {
				loading: true,
				error: false,
			}
		},
		computed: {
			showImage() {
				return !this.loading && this.src && !this.error
			},
			showLoading() {
				return this.loading
			},
			showError() {
				return !this.loading && this.error
			}
		},
		methods: {
			handleLoad() {
				this.loading = false
			},
			handleError() {
				this.loading = false;
				this.error = true
			}
		}
	}
</script>

<style scoped>
.avatar-view {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>