
























import {Vue, Component, Prop} from 'vue-property-decorator'
import AvatarView from '@/components/AvatarView.vue'
import { SupportTicketMessage } from '@/classes/TayehApi/User/Query/GetUserTicket.query'
@Component({
    components: {
        AvatarView
    },
})
export default class SupportTicketMessagesList extends Vue {
    @Prop() message: SupportTicketMessage;
}
