





























import {
    Vue,
    Component,
    Prop
} from 'vue-property-decorator'
import AvatarView from '@/components/AvatarView.vue'
import {
    GetSupportTicketResponse
} from '@/classes/TayehApi/User';

@Component({
    components: {
        AvatarView
    }
})
export default class SupportTicketMessagesList extends Vue {
    @Prop() ticket: GetSupportTicketResponse;
}
